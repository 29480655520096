import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  const { allPagesJson } = useStaticQuery(
    graphql`
      {
        allPagesJson(filter: { page: { eq: "not_found" } }) {
          nodes {
            page
            title
            heading
            description
          }
        }
      }
    `
  )

  const data = allPagesJson.nodes[0]

  return (
    <Layout>
      <Seo title={data.heading} />
      <h1>{data.heading}</h1>
      <p>{data.description}</p>
    </Layout>
  )
}

export default NotFoundPage
